exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-airserver-digital-signage-js": () => import("./../../../src/pages/airserver-digital-signage.js" /* webpackChunkName: "component---src-pages-airserver-digital-signage-js" */),
  "component---src-pages-amazon-fire-stick-digital-signage-js": () => import("./../../../src/pages/amazon-fire-stick-digital-signage.js" /* webpackChunkName: "component---src-pages-amazon-fire-stick-digital-signage-js" */),
  "component---src-pages-android-digital-signage-js": () => import("./../../../src/pages/android-digital-signage.js" /* webpackChunkName: "component---src-pages-android-digital-signage-js" */),
  "component---src-pages-appstore-js": () => import("./../../../src/pages/appstore.js" /* webpackChunkName: "component---src-pages-appstore-js" */),
  "component---src-pages-brightsign-player-software-js": () => import("./../../../src/pages/brightsign-player-software.js" /* webpackChunkName: "component---src-pages-brightsign-player-software-js" */),
  "component---src-pages-chrome-partnership-js": () => import("./../../../src/pages/chrome-partnership.js" /* webpackChunkName: "component---src-pages-chrome-partnership-js" */),
  "component---src-pages-communications-solutions-for-deskless-workers-ebook-js": () => import("./../../../src/pages/communications-solutions-for-deskless-workers-ebook.js" /* webpackChunkName: "component---src-pages-communications-solutions-for-deskless-workers-ebook-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-corporate-tv-digital-signage-js": () => import("./../../../src/pages/corporate-tv-digital-signage.js" /* webpackChunkName: "component---src-pages-corporate-tv-digital-signage-js" */),
  "component---src-pages-dashboard-finder-js": () => import("./../../../src/pages/dashboard-finder.js" /* webpackChunkName: "component---src-pages-dashboard-finder-js" */),
  "component---src-pages-digital-menu-board-software-js": () => import("./../../../src/pages/digital-menu-board-software.js" /* webpackChunkName: "component---src-pages-digital-menu-board-software-js" */),
  "component---src-pages-digital-menu-board-software-solution-js": () => import("./../../../src/pages/digital-menu-board-software-solution.js" /* webpackChunkName: "component---src-pages-digital-menu-board-software-solution-js" */),
  "component---src-pages-digital-signage-design-studio-js": () => import("./../../../src/pages/digital-signage-design-studio.js" /* webpackChunkName: "component---src-pages-digital-signage-design-studio-js" */),
  "component---src-pages-digital-signage-players-support-js": () => import("./../../../src/pages/digital-signage-players-support.js" /* webpackChunkName: "component---src-pages-digital-signage-players-support-js" */),
  "component---src-pages-digital-signage-raspberry-pi-js": () => import("./../../../src/pages/digital-signage-raspberry-pi.js" /* webpackChunkName: "component---src-pages-digital-signage-raspberry-pi-js" */),
  "component---src-pages-digital-signage-reseller-js": () => import("./../../../src/pages/digital-signage-reseller.js" /* webpackChunkName: "component---src-pages-digital-signage-reseller-js" */),
  "component---src-pages-digital-signage-software-features-js": () => import("./../../../src/pages/digital-signage-software-features.js" /* webpackChunkName: "component---src-pages-digital-signage-software-features-js" */),
  "component---src-pages-digital-signage-templates-js": () => import("./../../../src/pages/digital-signage-templates.js" /* webpackChunkName: "component---src-pages-digital-signage-templates-js" */),
  "component---src-pages-enterprise-digital-signage-js": () => import("./../../../src/pages/enterprise-digital-signage.js" /* webpackChunkName: "component---src-pages-enterprise-digital-signage-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-free-digital-signage-with-telegram-js": () => import("./../../../src/pages/free-digital-signage-with-telegram.js" /* webpackChunkName: "component---src-pages-free-digital-signage-with-telegram-js" */),
  "component---src-pages-fugo-chromebox-for-digital-signage-js": () => import("./../../../src/pages/fugo-chromebox-for-digital-signage.js" /* webpackChunkName: "component---src-pages-fugo-chromebox-for-digital-signage-js" */),
  "component---src-pages-fugo-digital-signage-player-comparison-js": () => import("./../../../src/pages/fugo-digital-signage-player-comparison.js" /* webpackChunkName: "component---src-pages-fugo-digital-signage-player-comparison-js" */),
  "component---src-pages-fugo-nuc-digital-signage-player-js": () => import("./../../../src/pages/fugo-NUC-digital-signage-player.js" /* webpackChunkName: "component---src-pages-fugo-nuc-digital-signage-player-js" */),
  "component---src-pages-grafana-digital-signage-js": () => import("./../../../src/pages/grafana-digital-signage.js" /* webpackChunkName: "component---src-pages-grafana-digital-signage-js" */),
  "component---src-pages-hubspot-dashboards-digital-signage-js": () => import("./../../../src/pages/hubspot-dashboards-digital-signage.js" /* webpackChunkName: "component---src-pages-hubspot-dashboards-digital-signage-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-corporate-digital-signage-js": () => import("./../../../src/pages/industries/corporate-digital-signage.js" /* webpackChunkName: "component---src-pages-industries-corporate-digital-signage-js" */),
  "component---src-pages-industries-digital-signage-for-education-js": () => import("./../../../src/pages/industries/digital-signage-for-education.js" /* webpackChunkName: "component---src-pages-industries-digital-signage-for-education-js" */),
  "component---src-pages-industries-digital-signage-for-hospitality-js": () => import("./../../../src/pages/industries/digital-signage-for-hospitality.js" /* webpackChunkName: "component---src-pages-industries-digital-signage-for-hospitality-js" */),
  "component---src-pages-industries-digital-signage-for-manufacturing-js": () => import("./../../../src/pages/industries/digital-signage-for-manufacturing.js" /* webpackChunkName: "component---src-pages-industries-digital-signage-for-manufacturing-js" */),
  "component---src-pages-industries-healthcare-digital-signage-js": () => import("./../../../src/pages/industries/healthcare-digital-signage.js" /* webpackChunkName: "component---src-pages-industries-healthcare-digital-signage-js" */),
  "component---src-pages-industries-restaurant-digital-signage-js": () => import("./../../../src/pages/industries/restaurant-digital-signage.js" /* webpackChunkName: "component---src-pages-industries-restaurant-digital-signage-js" */),
  "component---src-pages-industries-restaurant-digital-signage-menu-board-software-js": () => import("./../../../src/pages/industries/restaurant-digital-signage-menu-board-software.js" /* webpackChunkName: "component---src-pages-industries-restaurant-digital-signage-menu-board-software-js" */),
  "component---src-pages-industries-retail-digital-signage-js": () => import("./../../../src/pages/industries/retail-digital-signage.js" /* webpackChunkName: "component---src-pages-industries-retail-digital-signage-js" */),
  "component---src-pages-ise-2023-brochure-js": () => import("./../../../src/pages/ise-2023-brochure.js" /* webpackChunkName: "component---src-pages-ise-2023-brochure-js" */),
  "component---src-pages-jira-digital-signage-jsx": () => import("./../../../src/pages/jira-digital-signage.jsx" /* webpackChunkName: "component---src-pages-jira-digital-signage-jsx" */),
  "component---src-pages-klipfolio-digital-signage-js": () => import("./../../../src/pages/klipfolio-digital-signage.js" /* webpackChunkName: "component---src-pages-klipfolio-digital-signage-js" */),
  "component---src-pages-lg-digital-signage-software-js": () => import("./../../../src/pages/lg-digital-signage-software.js" /* webpackChunkName: "component---src-pages-lg-digital-signage-software-js" */),
  "component---src-pages-looker-digital-signage-dashboards-js": () => import("./../../../src/pages/looker-digital-signage-dashboards.js" /* webpackChunkName: "component---src-pages-looker-digital-signage-dashboards-js" */),
  "component---src-pages-looker-for-digital-signage-js": () => import("./../../../src/pages/looker-for-digital-signage.js" /* webpackChunkName: "component---src-pages-looker-for-digital-signage-js" */),
  "component---src-pages-postermywall-app-for-digital-signage-js": () => import("./../../../src/pages/postermywall-app-for-digital-signage.js" /* webpackChunkName: "component---src-pages-postermywall-app-for-digital-signage-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-qr-code-signage-on-screens-js": () => import("./../../../src/pages/qr-code-signage-on-screens.js" /* webpackChunkName: "component---src-pages-qr-code-signage-on-screens-js" */),
  "component---src-pages-resources-covid-digital-signage-future-js": () => import("./../../../src/pages/resources/covid-digital-signage-future.js" /* webpackChunkName: "component---src-pages-resources-covid-digital-signage-future-js" */),
  "component---src-pages-resources-pixelart-relaunch-js": () => import("./../../../src/pages/resources/pixelart-relaunch.js" /* webpackChunkName: "component---src-pages-resources-pixelart-relaunch-js" */),
  "component---src-pages-resources-print-vs-digital-js": () => import("./../../../src/pages/resources/print-vs-digital.js" /* webpackChunkName: "component---src-pages-resources-print-vs-digital-js" */),
  "component---src-pages-resources-remote-work-js": () => import("./../../../src/pages/resources/remote-work.js" /* webpackChunkName: "component---src-pages-resources-remote-work-js" */),
  "component---src-pages-salesforce-tv-dashboards-js": () => import("./../../../src/pages/salesforce-tv-dashboards.js" /* webpackChunkName: "component---src-pages-salesforce-tv-dashboards-js" */),
  "component---src-pages-samsung-smart-tv-digital-signage-platform-js": () => import("./../../../src/pages/samsung-smart-tv-digital-signage-platform.js" /* webpackChunkName: "component---src-pages-samsung-smart-tv-digital-signage-platform-js" */),
  "component---src-pages-screen-mirroring-software-for-digital-signage-js": () => import("./../../../src/pages/screen-mirroring-software-for-digital-signage.js" /* webpackChunkName: "component---src-pages-screen-mirroring-software-for-digital-signage-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-share-power-bi-dashboard-on-digital-signage-js": () => import("./../../../src/pages/share-power-bi-dashboard-on-digital-signage.js" /* webpackChunkName: "component---src-pages-share-power-bi-dashboard-on-digital-signage-js" */),
  "component---src-pages-sla-js": () => import("./../../../src/pages/sla.js" /* webpackChunkName: "component---src-pages-sla-js" */),
  "component---src-pages-social-media-wall-for-digital-signage-js": () => import("./../../../src/pages/social-media-wall-for-digital-signage.js" /* webpackChunkName: "component---src-pages-social-media-wall-for-digital-signage-js" */),
  "component---src-pages-sony-bravia-android-tv-js": () => import("./../../../src/pages/sony-bravia-android-tv.js" /* webpackChunkName: "component---src-pages-sony-bravia-android-tv-js" */),
  "component---src-pages-splunk-tv-dashboards-js": () => import("./../../../src/pages/splunk-tv-dashboards.js" /* webpackChunkName: "component---src-pages-splunk-tv-dashboards-js" */),
  "component---src-pages-tableau-digital-signage-js": () => import("./../../../src/pages/tableau-digital-signage.js" /* webpackChunkName: "component---src-pages-tableau-digital-signage-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-touch-screen-digital-signage-software-js": () => import("./../../../src/pages/touch-screen-digital-signage-software.js" /* webpackChunkName: "component---src-pages-touch-screen-digital-signage-software-js" */),
  "component---src-pages-tv-dashboards-js": () => import("./../../../src/pages/tv-dashboards.js" /* webpackChunkName: "component---src-pages-tv-dashboards-js" */),
  "component---src-pages-use-cases-company-channel-js": () => import("./../../../src/pages/use-cases/company-channel.js" /* webpackChunkName: "component---src-pages-use-cases-company-channel-js" */),
  "component---src-pages-user-stories-js": () => import("./../../../src/pages/user-stories.js" /* webpackChunkName: "component---src-pages-user-stories-js" */),
  "component---src-pages-vape-shop-advertising-digital-signage-js": () => import("./../../../src/pages/vape-shop-advertising-digital-signage.js" /* webpackChunkName: "component---src-pages-vape-shop-advertising-digital-signage-js" */),
  "component---src-pages-wiki-js": () => import("./../../../src/pages/wiki.js" /* webpackChunkName: "component---src-pages-wiki-js" */),
  "component---src-pages-zendesk-app-for-digital-signage-js": () => import("./../../../src/pages/zendesk-app-for-digital-signage.js" /* webpackChunkName: "component---src-pages-zendesk-app-for-digital-signage-js" */),
  "component---src-templates-app-js": () => import("./../../../src/templates/app.js" /* webpackChunkName: "component---src-templates-app-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-dashboard-js": () => import("./../../../src/templates/dashboard.js" /* webpackChunkName: "component---src-templates-dashboard-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-wiki-js": () => import("./../../../src/templates/wiki.js" /* webpackChunkName: "component---src-templates-wiki-js" */)
}

